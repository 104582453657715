import cepFetcher from 'cep-promise';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-final-form';
import { IBairro, ICidade, IEstado, IPais } from '../../models/adresses';
import { EnderecoFetcher } from '../../utils/address-utils';
import { onlyNumbers } from '../../utils/utils';

interface InitialOptions {
  cidade: ICidade[];
  pais: IPais[];
  estado: IEstado[];
  bairro: IBairro[];
}

interface Values {
  country?: string;
  paisId?: number;
  state?: string;
  estadoId?: number;
  city?: string;
  cidadeId?: number;
  neighborhood?: string;
  bairroId?: number | null;
  zipCode?: string;
  streetDescription?: string;
  neighborhoodFromCepFetcher?: string;
  existsCepFromService?: boolean;
}

export function useAddress(enderecoFetcher: EnderecoFetcher, name: string) {
  const form = useForm();
  const streetNumberRef = useRef<HTMLInputElement>(null);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [disabledCEPFields, setDisabledCEPFields] = useState(true);
  const [initialOptions, setInitialOptions] = useState<InitialOptions>({
    cidade: [],
    pais: [],
    estado: [],
    bairro: [],
  });

  const setFormValue = (field: string, value: any) => {
    form.change(name ? `${name}.${field}` : field, value);
  };

  const resetFields = () => {
    form.batch(() => {
      setFormValue('paisId', null);
      setFormValue('estadoId', null);
      setFormValue('cidadeId', null);
      setFormValue('bairroId', null);
      setFormValue('cepBairroId', null);
      setFormValue('neighborhood', null);
    });
  };

  const getInitialOptions = async (values: Values) => {
    let paisOptions: IPais[] = [];
    let estadoOptions: IEstado[] = [];
    let cidadeOptions: ICidade[] = [];
    let bairroOptions: (IBairro & { alternativeId?: number | string })[] = [];

    resetFields();

    let hierarquiaCompleta = null;

    const fetchPaisId = async (): Promise<number> => {
      if (values?.paisId) return values?.paisId;
      const paisList = await enderecoFetcher.getPaisListByTerm(
        values?.country,
        { limit: 1 },
      );
      return paisList[0]?.id;
    };

    const fetchEstadoId = async (paisId: number): Promise<number> => {
      if (values?.estadoId) return values?.estadoId;
      const estadoList = await enderecoFetcher.getEstadoListByPaisId(
        paisId,
        values?.state,
        { limit: 1 },
      );
      return estadoList[0]?.id;
    };

    const fetchCidadeId = async (estadoId: number): Promise<number> => {
      if (values?.cidadeId) return values?.cidadeId;

      const [cidade] = await enderecoFetcher.getCidadeListByEstadoId(
        estadoId,
        values?.city,
        { limit: 1 },
      );

      hierarquiaCompleta = !!cidade;

      return cidade?.id;
    };

    const fetchBairroId = async (cidadeId: number): Promise<number | null> => {
      if (values?.bairroId) return values.bairroId;

      const [bairro] = await enderecoFetcher.getBairroListByCidadeId(
        cidadeId,
        values?.neighborhood,
        { limit: 1 },
      );

      if (bairro?.nomeBairro === values?.neighborhoodFromCepFetcher) {
        setFormValue('cepBairroId', bairro?.id || null);
      }

      hierarquiaCompleta = !!bairro;

      return bairro?.id;
    };

    const paisId = await fetchPaisId();
    const estadoId = await fetchEstadoId(paisId);
    const cidadeId = await fetchCidadeId(estadoId);
    const bairroId = await fetchBairroId(cidadeId);

    if (!paisId || !estadoId || !cidadeId) {
      loadAddress(values.zipCode as string, {
        bairroId: values.bairroId,
        neighborhood: values.neighborhood,
        streetDescription: values.streetDescription,
      });
    }

    if (values?.existsCepFromService === false) {
      form.change(`${name}.createNewCep`, hierarquiaCompleta);
      form.change(`${name}.createCepForAllTenants`, hierarquiaCompleta);
    }

    if (values?.country) {
      paisOptions = [{ id: paisId, nomePais: values.country }];
      setFormValue('paisId', paisId);
      setFormValue('country', values.country || null);
    }
    if (values?.state) {
      estadoOptions = [{ id: estadoId, nomeEstado: values.state }];
      setFormValue('estadoId', estadoId);
      setFormValue('state', values.state || null);
    }
    if (values?.city) {
      cidadeOptions = [{ id: cidadeId, nomeCidade: values.city }];
      setFormValue('cidadeId', cidadeId);
      setFormValue('city', values.city || null);
    }
    if (values?.neighborhood) {
      bairroOptions = [
        {
          id: bairroId || null,
          nomeBairro: values.neighborhood,
          alternativeId: bairroId ? undefined : values.neighborhood,
        },
      ];
      setFormValue('bairroId', bairroId || null);
      setFormValue('neighborhood', values.neighborhood || null);
    }

    if (values?.existsCepFromService || hierarquiaCompleta)
      setDisabledCEPFields(true);

    setInitialOptions({
      bairro: bairroOptions,
      cidade: cidadeOptions,
      estado: estadoOptions,
      pais: paisOptions,
    });
  };

  const loadAddress = async (value: string, fixedValues?: Partial<Values>) => {
    const cep = onlyNumbers(value);
    if (cep.length === 8) {
      setLoadingAddress(true);

      let enderecoFromService: {
        bairro?: IBairro;
        cidade?: ICidade;
        estado?: IEstado;
        pais?: IPais;
      } | null = {};
      let enderecoFromCepFetcher: {
        neighborhood?: string;
        city?: string;
        state?: string;
        street?: string;
      } | null = {};

      try {
        enderecoFromService = await enderecoFetcher.getEnderecoByCep(cep);
      } catch (err) {
        enderecoFromService = null;
      }

      try {
        enderecoFromCepFetcher = await cepFetcher(cep);
      } catch (err) {
        enderecoFromCepFetcher = null;
      }

      let existsCepFromService = false;
      let existsCepFromCepFetcher = false;

      if (
        enderecoFromService?.bairro &&
        enderecoFromService?.cidade &&
        enderecoFromService?.estado &&
        enderecoFromService?.pais
      ) {
        existsCepFromService = true;
      }

      if (
        enderecoFromCepFetcher?.neighborhood &&
        enderecoFromCepFetcher?.city &&
        enderecoFromCepFetcher?.state &&
        enderecoFromCepFetcher?.street
      ) {
        existsCepFromCepFetcher = true;
      }

      if (!existsCepFromService && !existsCepFromCepFetcher) {
        resetFields();
        setDisabledCEPFields(false);
        if (fixedValues?.bairroId) {
          form.change(`${name}.bairroId`, fixedValues?.bairroId);
        }
        if (fixedValues?.neighborhood) {
          form.change(`${name}.neighborhood`, fixedValues?.neighborhood);
          form.change(`${name}.fixedNeighborhood`, fixedValues?.neighborhood);
        }
        form.change(`${name}.createCepForAllTenants`, false);
        form.change(`${name}.createNewCep`, true);
      } else {
        const address = {
          street: enderecoFromCepFetcher?.street || '',
          neighborhood:
            enderecoFromService?.bairro?.nomeBairro ||
            enderecoFromCepFetcher?.neighborhood ||
            '',
          city:
            enderecoFromService?.cidade?.nomeCidade ||
            enderecoFromCepFetcher?.city ||
            '',
          state:
            enderecoFromService?.estado?.nomeEstado ||
            enderecoFromCepFetcher?.state ||
            '',
          country: enderecoFromService?.pais?.nomePais || 'Brasil',
        };

        const neighborhoodFromCepFetcher = enderecoFromCepFetcher?.neighborhood;

        if (existsCepFromService) {
          address.neighborhood =
            enderecoFromService?.bairro?.nomeBairro || address.neighborhood;
          address.city =
            enderecoFromService?.cidade?.nomeCidade || address.city;
          address.state =
            enderecoFromService?.estado?.nomeEstado || address.state;
          address.country =
            enderecoFromService?.pais?.nomePais || address.country;
        }

        setFormValue('createNewCep', !existsCepFromService);

        form.change(
          `${name}.streetDescription`,
          fixedValues?.streetDescription || address.street,
        );

        if (address?.country) {
          const values = {
            country: address.country,
            paisId: enderecoFromService?.pais?.id,
            state: address.state,
            estadoId: enderecoFromService?.estado?.id,
            city: address.city,
            cidadeId: enderecoFromService?.cidade?.id,
            neighborhood: address.neighborhood,
            bairroId: enderecoFromService?.bairro?.id,
            neighborhoodFromCepFetcher,
            existsCepFromService,
          };
          await getInitialOptions({ ...values, ...fixedValues });
        }

        streetNumberRef.current?.focus();
      }
    }
    setLoadingAddress(false);
  };

  useEffect(() => {
    const initialValues = name
      ? form.getState().initialValues?.[name]
      : form.getState().initialValues;
    if (initialValues && initialValues?.country)
      getInitialOptions(initialValues);
  }, []);

  return {
    initialOptions,
    loadAddress,
    loadingAddress,
    streetNumberRef,
    disabledCEPFields,
    getInitialOptions,
  };
}
