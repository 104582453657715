const recursosLabels = {
  home: 'Home',
  'calendario-atendimento': 'Calendário',
  'dashboard-venda': 'Dash Vendas',
  'dashboard-alugueis': 'Dash Aluguéis',
  'dashboard-marketing': 'Dash Marketing',
  'dashboard-roletas': 'Dash Roleta',
  clientes: 'Clientes',
  imoveis: 'Imóveis',
  empreendimentos: 'Condomínios',
  usuarios: 'Usuários',
  teams: 'Times',
  escritorios: 'Escritórios',
  site: 'Configurações do Site',
  configuracoes: 'Configurações',
  'acesso-historico-recursos': 'Históricos e Acessos',
  agendamentos: 'Agendamentos',
  negociacoes: 'Negociações',
  'analise-credito': 'Análise de crédito',
  contratos: 'Contratos',
  'integrations-config': 'Integrações',
  'integrations-reports': 'Relatório de integração',
  'eventos-envio-email': 'E-mails enviados',
  'chaveiro-emprestimo': 'Chaves',
  metas: 'Metas',
  roletas: 'Cadastro roletas',
  'roletas-view': 'Roletas',
  'eventos-roletas': 'Eventos roletas',
  'roletas-clientes-view': 'Clientes roletas',
  'relatorio-imoveis': 'Relatório de Imóveis',
  'relatorio-condominios': 'Relatório de Condomínios',
  'relatorio-fechamentos': 'Relatório de Fechamentos',
  'relatorio-chaves': 'Relatório de Chaves',
  'relatorio-visitas': 'Relatório de Visitas',
  'relatorio-desempenho': 'Relatório de Desempenho',
  'relatorio-leads': 'Relatório de Leads',
  'relatorio-marketing': 'Relatório de Marketing',
  'relatorio-aniversariantes': 'Relatório de Aniversariantes',
  recebimentos: 'Recebimentos',
  pagamentos: 'Pagamentos',
  'relatorio-integracao-leads': 'Relatório de Integração de Leads',
  tags: 'Tags',
  'termos-impressao': 'Termos para impressão',
  fechamentos: 'Fechamentos',
};

export const formatRecurso = (paginaOrigem) => {
  const pagina = paginaOrigem.split('/').slice(1, 2);
  return recursosLabels[pagina] || pagina[0];
};

const historicoTabs = (parametrosUrl) => {
  switch (parametrosUrl) {
    case '?tab=1':
      return 'Acesso a recursos';
    default:
      return 'Histórico de edições';
  }
};

const imovelTabs = (parametrosUrl) => {
  switch (parametrosUrl) {
    case '?tab=1':
      return 'Fotos do condomínio';
    case '?tab=2':
      return 'Documentos';
    default:
      return '';
  }
};

const imovelViewTabs = (parametrosUrl) => {
  switch (parametrosUrl) {
    case '?tab=1':
      return 'Visitas e propostas';
    case '?tab=2':
      return 'Avaliações e desempenho';
    case '?tab=3':
      return 'Informações e detalhes';
    case '?tab=4':
      return 'Dados e complementos';
    case '?tab=5':
      return 'Dados e anúncios';
    case '?tab=6':
      return 'Proprietário';
    case '?tab=7':
      return 'Histórico de edição';
    default:
      return 'Fotos';
  }
};

const condominioTabs = (parametrosUrl) => {
  switch (parametrosUrl) {
    case '?tab=1':
      return 'Fotos';
    case '?tab=2':
      return 'Documentos';
    default:
      return '';
  }
};

const condominioViewTabs = (parametrosUrl) => {
  switch (parametrosUrl) {
    case '?tab=1':
      return 'Visitas';
    case '?tab=2':
      return 'Avaliações e desempenho';
    case '?tab=3':
      return 'Ficha';
    case '?tab=4':
      return 'Unidades';
    case '?tab=5':
      return 'Propostas';
    case '?tab=6':
      return 'Documentos';
    default:
      return 'Fotos';
  }
};

const siteTabs = (parametrosUrl) => {
  switch (parametrosUrl) {
    case '?tab=1':
      return 'Tela inicial';
    case '?tab=2':
      return 'Links rodapé';
    case '?tab=3':
      return 'Agendamento';
    case '?tab=4':
      return 'SEO';
    case '?tab=5':
      return 'Scripts';
    case '?tab=6':
      return 'Cores';
    case '?tab=7':
      return 'Formulário';
    case '?tab=8':
      return 'Quem somos';
    case '?tab=9':
      return 'Blog';
    default:
      return 'Site';
  }
};

export const formatTabs = (paginaOrigem, parametrosUrl) => {
  const isView = paginaOrigem.includes('view');

  if (paginaOrigem.includes('imoveis') && !isView) {
    return {
      paginaOrigem: paginaOrigem,
      parametrosUrl: parametrosUrl,
      aba: imovelTabs(parametrosUrl),
    };
  }
  if (paginaOrigem.includes('imoveis') && isView) {
    return {
      paginaOrigem: paginaOrigem,
      parametrosUrl: parametrosUrl,
      aba: imovelViewTabs(parametrosUrl),
    };
  }
  if (paginaOrigem.includes('empreendimentos') && !isView) {
    return {
      paginaOrigem: paginaOrigem,
      parametrosUrl: parametrosUrl,
      aba: condominioTabs(parametrosUrl),
    };
  }
  if (paginaOrigem.includes('empreendimentos') && isView) {
    return {
      paginaOrigem: paginaOrigem,
      parametrosUrl: parametrosUrl,
      aba: condominioViewTabs(parametrosUrl),
    };
  }
  if (paginaOrigem.includes('site')) {
    return {
      paginaOrigem: paginaOrigem,
      parametrosUrl: parametrosUrl,
      aba: siteTabs(parametrosUrl),
    };
  }
  if (paginaOrigem.includes('historico-edicoes') && !isView) {
    return {
      paginaOrigem: paginaOrigem,
      parametrosUrl: parametrosUrl,
      aba: historicoTabs(parametrosUrl),
    };
  }
  return { paginaOrigem, parametrosUrl };
};
