import { IEndereco } from './adresses';
import { User } from './user';

export enum TipoTelefone {
  FIXO = 'FIXO',
  CELULAR = 'CELULAR',
  COMERCIAL = 'COMERCIAL',
  OUTRO = 'OUTRO',
}

export interface Telefones {
  id: number;
  tenantId: number;
  clienteId: number;
  ddi: string;
  numero: string;
  tipo: TipoTelefone;
  principal: boolean;
  descricao?: string;
}

interface DiasSemAtendimento {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}

export enum acaoAtendimentoEnum {
  AGENDAR_VISITA = 'AGENDAR_VISITA',
  AGENDAR_VIDEOCONFERENCIA = 'AGENDAR_VIDEOCONFERENCIA',
  OFERECER_UM_IMOVEL = 'OFERECER_UM_IMOVEL',
  RETORNAR_CONTATO = 'RETORNAR_CONTATO',
  REAGENDAR = 'REAGENDAR',
  FEEDBACK_DE_VISITA = 'FEEDBACK_DE_VISITA',
  NAO_ESQUECA_DE_MIM_MANUTENCAO = 'NAO_ESQUECA_DE_MIM_MANUTENCAO',
  PRIMEIRO_ATENDIMENTO = 'PRIMEIRO_ATENDIMENTO',
  CONVERSA_WHATSAPP = 'CONVERSA_WHATSAPP',
  MENSAGEM_CHAT2DESK = 'MENSAGEM_CHAT2DESK',
  COMENTARIO_CHAT2DESK = 'COMENTARIO_CHAT2DESK',
  TRANSFERENCIA_MANUAL = 'TRANSFERENCIA_MANUAL',
  PROPOSTA_CADASTRADA = 'PROPOSTA_CADASTRADA',
}

export enum statusLeadsEnum {
  SEM_ATENDIMENTO = 'SEM_ATENDIMENTO',
  EM_ESPERA = 'EM_ESPERA',
  EM_ATENDIMENTO = 'EM_ATENDIMENTO',
  INATIVADO = 'INATIVADO',
  INATIVADO_AUTOMATICAMENTE = 'INATIVADO_AUTOMATICAMENTE',
  COMPRADOR = 'COMPRADOR',
  EM_ESPERA_OFERTA_ATIVA = 'EM_ESPERA_OFERTA_ATIVA',
}

export enum tipoCanalEnum {
  MENSAGEM = 'MENSAGEM',
  LIGACAO = 'LIGACAO',
  EMAIL = 'EMAIL',
  PRESENCIAL = 'PRESENCIAL',
  INTERNET = 'INTERNET',
  CHAT = 'CHAT',
}

export enum TipoContratoEnum {
  PF = 'PF',
  PJ = 'PJ',
}

export enum tipoMidiaEnum {
  SITE = 'SITE',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PESSOAL = 'FACEBOOK_PESSOAL',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_PESSOAL = 'INSTAGRAM_PESSOAL',
  GRUPO_ZAP = 'GRUPO_ZAP',
  ZAP_IMOVEIS = 'ZAP_IMOVEIS',
  VIVA_REAL = 'VIVA_REAL',
  OLX = 'OLX',
  CASA_MINEIRA = 'CASA_MINEIRA',
  IMOVELWEB = 'IMOVELWEB',
  SUB100 = 'SUB100',
  AGENDA_CAFE = 'AGENDA_CAFE',
  GOOGLE = 'GOOGLE',
  RD_STATION = 'RD_STATION',
  FOLHETO = 'FOLHETO',
  CARTAO_VISITA = 'CARTAO_VISITA',
  INDICACAO = 'INDICACAO',
  EVENTO = 'EVENTO',
  PLACA = 'PLACA',
  OUTDOOR = 'OUTDOOR',
  TV = 'TV',
  CAPTACAO = 'CAPTACAO',
  LANDING_PAGE = 'LANDING_PAGE',
  CLIQUEI_MUDEI = 'CLIQUEI_MUDEI',
  CHAVES_NA_MAO = 'CHAVES_NA_MAO',
  CREDPAGO = 'CREDPAGO',
  IMOVEIS_BC = 'IMOVEIS_BC',
  ALTO_PADRAO_BC = 'ALTO_PADRAO_BC',
  DFIMOVEIS = 'DFIMOVEIS',
  '123I' = '123I',
  APTO = 'APTO',
  DGBZ = 'DGBZ',
  CHAT2DESK = 'CHAT2DESK',
  ATTRIA = 'ATTRIA',
  PLANTAO = 'PLANTAO',
  RELACIONAMENTO = 'RELACIONAMENTO',
  OLHO_MAGICO = 'OLHO_MAGICO',
  DREAMCASA = 'DREAMCASA',
  YOUTUBE = 'YOUTUBE',
  OUTROS = 'OUTROS',
}

export enum EstadoCivil {
  SOLTEIRO = 'SOLTEIRO',
  CASADO = 'CASADO',
  SEPARADO = 'SEPARADO',
  DIVORCIADO = 'DIVORCIADO',
  VIUVO = 'VIUVO',
}

export enum RegimeCasamento {
  COMUNHAO_PARCIAL = 'COMUNHAO_PARCIAL',
  COMUNHAO_UNIVERSAL = 'COMUNHAO_UNIVERSAL',
  PARTICIPACAO_AQUESTOS = 'PARTICIPACAO_AQUESTOS',
  SEPARACAO_TOTAL = 'SEPARACAO_TOTAL',
  SEPARACAO_OBRIGATORIA = 'SEPARACAO_OBRIGATORIA',
}

export interface IDadosContratuais {
  id: number;
  tenantId: number;
  clienteId: number;
  nomeCompleto: string;
  dataNascimento: Date;
  telefone: string;
  tipoTelefone: TipoTelefone;
  email: string;
  identidade: string;
  cpf: string;
  endereco?: IEndereco;
  profissao: string;
  nacionalidade: string;
  estadoCivil: EstadoCivil;
  regimeCasamento: RegimeCasamento;
  enderecoId: number;
  nomeCompletoConjuge: string;
  identidadeConjuge: string;
  cpfConjuge: string;
  profissaoConjuge: string;
  nacionalidadeConjuge: string;
  emailConjuge: string;
  dadosComerciais: { renda: number };
}

export interface IDadosContratuaisPj {
  id: number;
  tenantId: number;
  cnpj: string;
  razaoSocial: string;
  nomeFantasia: string;
}

export interface CustomerListRecord {
  id: number;
  nome: string;
  status: string;
  alerta: string;
  responsavelId?: number;
  responsavelNome?: string;
  responsavelTeamId?: number;
  diasSemAtendimento: DiasSemAtendimento;
  proximoAtendimento: Date;
  proximoAtendimentoData: Date;
  proximoAtendimentoAcao: acaoAtendimentoEnum;
  tags: string;
  tenantId: number;
  deletedAt?: Date;
  email?: string;
  pretensaoCliente: string;
  temperatura: string;
  createdAt: Date;
  interessado: boolean;
  proprietario: boolean;
  updatedAt: Date;
  ultimoInteresse?: any;
  telefones: Telefones[];
  contato: Telefones;
  proxAtd: string;
  corretor?: string;
  diasSemAtd: number;
  createdAtFormated: string;
  telefone: string;
  valorInteresseVenda?: any;
  valorInteresseLocacao?: any;
  ultimoInteresseNomeEmpreendimento?: string;
  ultimoInteresseCodigoImovel?: string;
  dadosContratuais?: IDadosContratuais;
  possuiMensagensWhatsapp?: boolean;
}
export interface IResponsavelCliente {
  id: number;
  dhInicio: Date | string;
  dhFim?: Date | string;
  clienteId: number;
  responsavelId: number;
}

export enum PretensaoInteresse {
  VENDA = 'VENDA',
  LOCACAO = 'LOCACAO',
  AMBOS = 'AMBOS',
}

export interface Customer {
  id: number;
  tenantId: number;
  name: string;
  email?: string;
  emailSendFrequency: number;
  enabled: boolean;
  note?: string;
  photoUrl?: string;
  tipoCanal?: tipoCanalEnum;
  tipoMidia?: tipoMidiaEnum;
  outrasMidias?: string;
  pretensaoCliente?: PretensaoInteresse;
  isProprietario: boolean;
  isInteressado: boolean;
  migracao: boolean;
  idMigracao?: number;
  usuarioCadastroId?: number;
  responsavelId?: number;
  responsaveis?: IResponsavelCliente[];
  dadosContratuais?: IDadosContratuais;
  dadosContratuaisPj?: IDadosContratuaisPj;
  statusCliente?: statusLeadsEnum;
  usuarioCadastro?: User;
  telefones?: Telefones[];
  tipoContrato: TipoContratoEnum;
}

export enum InterestStatus {
  NOVO = 'NOVO',
  EM_ATENDIMENTO = 'EM_ATENDIMENTO',
  FINALIZADO = 'FINALIZADO',
}

export interface Interest {
  id: number;
  tenantId: number;
  origin: string;
  status: InterestStatus;
  message: string;
  propertyId?: number;
  codigoImovel?: string;
  empreendimentoId?: number;
  customerId: number;
  pretensao: PretensaoInteresse;
  criadoPor?: number;
  metaFields?: string;
  utmFields?: string;
}

export interface CustomerValidation {
  id: number;
  tenantId: number;
  createdAt: Date;
  updatedAt: Date;
  nome: string;
  email: string;
  telefones: Pick<
    Telefones,
    'ddi' | 'numero' | 'tipo' | 'principal' | 'descricao'
  >[];
  status: statusLeadsEnum;
  responsavelId: number;
  responsavel: string;
}
