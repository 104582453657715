import isPlainObject from 'lodash/isPlainObject';
import fromPairs from 'lodash/fromPairs';

export type Key = string | number | symbol;

export type Path = Key[];

/**
 * Applies walker to every component of the input. When an array is encountered,
 * walker is first applied to its elements and then to the array itself; when
 * an object is encountered, walker is first applied to all the values and then
 * to the object.
 */
export default function walk(
  input: any,
  walker: (value: any, path: Path) => any,
  basePath: Path = [],
): any {
  if (Array.isArray(input)) {
    return walker(
      input.map((element, index) => {
        return walk(element, walker, [...basePath, index]);
      }),
      basePath,
    );
  } else if (isPlainObject(input)) {
    return walker(
      fromPairs(
        [
          ...Object.getOwnPropertyNames(input),
          ...Object.getOwnPropertySymbols(input),
        ].map((key) => {
          return [key, walk(input[key], walker, [...basePath, key])];
        }),
      ),
      basePath,
    );
  } else {
    return walker(input, basePath);
  }
}
