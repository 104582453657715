import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@elentari/components-eve/components/TextField';
import NumberFormatCustom from './NumberFormatCustom';
import { Typography } from '@material-ui/core';

const MoneyTextField = ({ input, meta, startAdornment, ...props }) => {
  return (
    <TextField
      {...props}
      {...input}
      error={meta?.touched && !!meta?.error}
      helperText={meta?.touched && meta?.error}
      InputProps={{
        ...props.InputProps,
        startAdornment: startAdornment ? (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ) : (
          <InputAdornment position="start">
            <Typography>R$</Typography>
          </InputAdornment>
        ),
        inputComponent: MoneyFormat,
      }}
      placeholder="0,00"
    />
  );
};

export const MoneyFormat = ({ inputRef, onChange, ...rest }) => {
  return (
    <NumberFormatCustom
      inputRef={inputRef}
      onChange={onChange}
      {...rest}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
};

export default MoneyTextField;
