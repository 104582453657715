export const defaultConfig = {
  useFixture: false,
  baseURL: 'http://localhost:3030',
  socketURL: 'http://localhost:3030',
  uploadBaseURL: 'http://localhost:3033',
  integracaoZapURL: 'http://localhost:3040/',
  integracaoOlxURL: 'http://localhost:3041/',
  integracaoNaventURL: 'http://localhost:3042/',
  integracaoNaventAccessToken: '72FE3955B3DC2F76A337AAB36DC87',
  integracaoFlipURL: 'http://localhost:3044/',
  integracaoCliqueiMudeiURL: 'http://localhost:3040/',
  integracaoCliqueiMudeiLeadsURL: 'http://localhost:3044/',
  integracoesApiURL: 'http://localhost:3044/',
  integracoesAccessToken: '12DB5CD92871BF29346AB5A926126',
  whatsappSyncURL: 'ws://localhost:3001',
  chat2DeskURL: 'https://api.chat24.io',
  mercadoLivreBaseAuthURL:
    'https://auth.mercadolivre.com.br/authorization?response_type=code',
  googleTokenApi:
    process.env.GOOGLE_TOKEN_API || 'AIzaSyCCiuaiH_BpkwPDNOoYPrWZBcVIos6e55I',
  facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID || '820569455285484',
  firebaseApiKey: process.env.REACT_APP_API_KEY,
  firebaseAuthDomain: process.env.REACT_APP_AUTH_DOMAIN,
  firebaseProjectId: process.env.REACT_APP_PROJECT_ID,
  firebaseAppId: process.env.REACT_APP_APP_ID,
  firebaseMessagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  firebasePublicVapidKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
  refreshTokenIntervalMinutes:
    Number(process.env.REACT_APP_REFRESH_TOKEN_INTERVAL) || 60,
  salesMachineKey: process.env.REACT_APP_SALES_MACHINE_KEY || '',
  apmActive: process.env.REACT_APP_APM_ACTIVE === 'true',
  apmServiceName: process.env.REACT_APP_APM_SERVICE_NAME || 'imobiliaria-web',
  apmServerUrl: process.env.REACT_APP_APM_SERVER_URL || 'http://localhost:8200',
  apmDistributedTracingOrigins:
    process.env.REACT_APP_APM_DISTRIBUTED_TRACING_ORIGINS ||
    'http://localhost:3030/',
  apmEnvironment: process.env.REACT_APP_APM_ENVIRONMENT || 'development',
  apmBreakdownMetrics: process.env.REACT_APP_APM_BREAKDOWN_METRICS === 'true',
};

export type DefaultConfig = typeof defaultConfig;
